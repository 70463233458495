import { Product } from '../../shared/model/product';
import { Brand, Brandlist } from '../../shared/model/product';
import {
  BrandListPage,
  HomeInfoRes,
  MobileBrandList,
  SignatureReq,
  SignatureStatus,
} from '../../shared/model/home';
import { HttpService } from '../../shared/service/http.service';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../shared/service/login.service';
import { Injectable } from '@angular/core';
import { LoaderService } from '../../shared/service/loader.service';
import { MbrInfo } from '../../shared/model/member';
import { PagetagService } from '../../shared/service/pagetag.service';
import { ParentBrandService } from '../../shared/service/parent-brand.service';
import { CreateCart, DeleteCartReq } from '../../shared/model/cart';
import { environment } from '../../../environments/environment';
import { HeaderService } from '../../shared/service/header.service';
declare var iCashJSContext: any;

@Injectable()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  // modal
  modalTiTle = '';
  modalMsg = '';
  isModalOpen = false;
  // signatureContent = '是否願意將證券、產險、銀行點數匯入到悠遊付點數兌換平台使用。';
  // isSignatureCheckboxChecked = false;

  isMobile: boolean = false;
  // 快速累積點數
  brandSlider: HTMLElement;
  brandInnerSlider: HTMLElement;
  brandSliderCounter = 0;
  // 推薦品牌
  brandCarouselTotalAmount: number = 0;
  brandCarouselTotalPages: number = 0;
  brandCarouselItemsPerPage: number;
  brandCarouselCounter: number = 0;
  brandCarouselInterval: ReturnType<typeof setTimeout>;
  //API取得資料
  homeInfo: HomeInfoRes = new HomeInfoRes();
  mobileBrandList: MobileBrandList = new MobileBrandList();
  mobileHitList: MobileBrandList = new MobileBrandList();
  userInfo: MbrInfo;

  brands: Brand[];
  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private router: Router,
    private elements: ElementRef,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private pagetagService: PagetagService,
    private parentBrandService: ParentBrandService,
    public headerService: HeaderService
  ) {}

  ngOnInit(): void {
    this.callPagetagService();
    this.headerService.Footer = true;
    this.headerService.index = true;
    this.userInfo = this.loginService.GetLocalInfo();
    this.resetBrandCarouselSpec();

    if (window.innerWidth < 768) {
      this.isMobile = true;
    }

    this.brandSlider = this.elements.nativeElement.querySelector(
      '.accumulated-points__row-wrap'
    );
    this.brandInnerSlider = this.elements.nativeElement.querySelector(
      '.accumulated-points__row'
    );


    this.getHomeInfo();
  }

  ngOnDestroy() {
    this.clearBrandCarouselInterval();
    this.headerService.Footer = false;
    this.headerService.index = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize($event?: any) {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.resetBrandCarouselSpec();
    this.onBrandCarouselChange(0);
    this.onBrandSliderChange('init');
  }

  onBrandSliderChange(condition: string, $event?: any) {
    if ($event) {
      $event.preventDefault();
    }
    const sliderWidth = this.brandSlider.offsetWidth;
    const innerSliderWidth = this.brandInnerSlider.scrollWidth;
    const limitOffset = Math.abs(sliderWidth - innerSliderWidth);
    let currentOffset =
      this.brandInnerSlider.style.transform != ''
        ? this.brandInnerSlider.style.transform
        : 'translateX(0px)';
    //擷取屬性值的數字部分
    currentOffset = currentOffset.slice(10);
    currentOffset = currentOffset.slice(1);
    currentOffset = currentOffset.slice(0, -3);
    switch (condition) {
      case 'prev':
        this.brandInnerSlider.style.transform = `translateX(0px)`;
        this.brandSliderCounter = 0;
        break;
      case 'next':
        this.brandInnerSlider.style.transform = `translateX(-${limitOffset}px)`;
        this.brandSliderCounter = 1;
        break;
      case 'init':
        this.brandInnerSlider.style.transform = 'translateX(0px)';
        this.brandSliderCounter = 0;
    }
  }

  resetBrandCarouselSpec() {
    let windowWidth = window.innerWidth;
    if (windowWidth >= 1200) {
      this.brandCarouselItemsPerPage = 5;
    } else if (windowWidth >= 992 && windowWidth < 1200) {
      this.brandCarouselItemsPerPage = 4;
    } else if (windowWidth >= 768 && windowWidth < 992) {
      this.brandCarouselItemsPerPage = 3;
    } else {
      this.brandCarouselItemsPerPage = 4;
    }

    this.brandCarouselTotalPages = Math.ceil(
      this.brandCarouselTotalAmount / this.brandCarouselItemsPerPage
    );
  }

  onBrandCarouselChange(index: number, $event?: any) {
    if ($event) {
      $event.preventDefault();
    }
    this.brandCarouselCounter = index;

    let brandCarousel: HTMLElement;
    if (window.innerWidth >= 768) {
      brandCarousel = this.elements.nativeElement.querySelector(
        '.brand-slider-desktop__row'
      );
    } else {
      brandCarousel = this.elements.nativeElement.querySelector(
        '.brand-slider-mobile__row-wrap'
      );
    }

    if (brandCarousel) {
      brandCarousel.style.transform = `translateX(-${
        this.brandCarouselCounter * 100
      }%)`;
    }

    if ($event) {
      // 重新計算timout 時間
      this.clearBrandCarouselInterval();
      // this.setBrandCarouselInterval();
    }
  }

  setBrandCarouselInterval() {
    const _this = this;

    this.brandCarouselInterval = setInterval(function () {
      _this.resetBrandCarouselSpec();
      if (
        _this.brandCarouselCounter >=
        Math.ceil(
          _this.homeInfo.brands.length / _this.brandCarouselItemsPerPage
        ) -
          1
      ) {
        _this.brandCarouselCounter = 0;
      } else {
        _this.brandCarouselCounter++;
      }
      const index = _this.brandCarouselCounter;
      _this.onBrandCarouselChange(index);
    }, 5000);
  }

  clearBrandCarouselInterval() {
    clearInterval(this.brandCarouselInterval);
  }

  // for *ngFor
  createEmptyArray(num: number) {
    return new Array(num);
  }

  getHomeInfo() {
    //開啟loader
    this.loaderService.start();
    this.httpService
      .get('/Home/Home?MbrID=' + this.userInfo.ID)
      .subscribe((x: any) => {
        this.homeInfo = x;
        this.setParentBrandList(this.homeInfo.parentBrands);

        //整理手機版品牌列表
        var aaaa = new Array();
        if (this.homeInfo.brands.length > 0) {
          for (let i = 0; i < this.homeInfo.brands.length; i++) {
            var a = Math.floor(i);
            if (this.mobileBrandList.brandLists[a]) {
              this.mobileBrandList.brandLists[a].brands.push(
                this.homeInfo.brands[i]
              );
            } else {
              this.mobileBrandList.brandLists[a] = new BrandListPage();
              this.mobileBrandList.brandLists[a].brands.push(
                this.homeInfo.brands[i]
              );
            }
          }
        }

        //使用版型 推薦商品一 api資料hotProducts
        if (this.homeInfo.hotProducts.length > 0) {
          for (let i = 0; i < this.homeInfo.hotProducts.length; i++) {
            var a = Math.floor(i);
            if (this.mobileBrandList.productLists[a]) {
              this.mobileBrandList.productLists[a].products.push(
                this.homeInfo.hotProducts[i]
              );
            } else {
              this.mobileBrandList.productLists[a] = new BrandListPage();
              this.mobileBrandList.productLists[a].products.push(
                this.homeInfo.hotProducts[i]
              );
            }
          }
        }
        //定義推薦品牌數量
        this.brandCarouselTotalAmount = this.homeInfo.brands.length;
        this.brandCarouselTotalPages = Math.ceil(
          this.brandCarouselTotalAmount / this.brandCarouselItemsPerPage
        );
        //設定推薦品牌輪播
        // this.setBrandCarouselInterval();
        //關掉loader
        this.loaderService.stop();
      });
  }

  setParentBrandList(brands: Brand[]) {
    this.parentBrandService.parentBrandList.next(brands);
    this.brands = brands;
  }


  loginRedirect() {
    let productQuery = sessionStorage.getItem('productQuery');

    if (productQuery) {
      this.router.navigate([productQuery]);
      sessionStorage.removeItem('productQuery');
      return;
    }

    location.href = '/';
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '首頁');
  }

  toggleToFavorite(id: number, isCollect: boolean) {
    const _this = this;
    if (!this.userInfo.IsLogin) {
      this.modalTiTle = '收藏';
      this.modalMsg = '請先登入會員';
      this.isModalOpen = true;
      return;
    }
    if (!isCollect) {
      let args = new CreateCart();
      args.isDetailId = false;
      args.Amount = 1;
      args.MemberId = this.userInfo.ID;
      args.Category1_id = 0;
      args.Category2_id = 0;
      args.ProductDtlId = id;

      this.httpService.post('/CartNew/CreateColl', args).subscribe((x: any) => {
        if (x.result == '成功') {
          this.modalTiTle = '收藏';
          this.modalMsg = '收藏成功';
          this.isModalOpen = true;
          changeCollectStatus(id);
        } else if (x.result == '重複') {
          this.modalTiTle = '收藏';
          this.modalMsg = '無法重複收藏';
          this.isModalOpen = true;
        }
      });
      return;
    }

    let args = new DeleteCartReq();
    args.isCartId = false;
    args.Ids.push(id);
    args.isAddCollect = false;
    this.httpService.post('/CartNew/Delete', args).subscribe((x: any) => {
      this.isModalOpen = true;
      this.modalTiTle = '收藏';
      this.modalMsg = '刪除成功';
      changeCollectStatus(id);
    });

    function changeCollectStatus(productId: number) {
      _this.homeInfo.hotProducts.forEach((item) => {
        if (item.id == productId) {
          item.isCollect = !item.isCollect;
        }
      });
    }
  }
  goTicket() {
    this.router.navigate(['/member/ticket']);
  }
  goActivity() {
    this.router.navigate(['/event/13']);
  }
  goOrder() {
    this.router.navigate(['/member/order']);
  }

  pay() {
    let logins = this.loginService;
    alert(iCashJSContext);
    try {
      var tradeToken = "iikipS2y%2BkWFlOKhPs4Q%2BauZT1SMxc%2BSVS3CGqvnpxtD6y8Kgh%2BoT3tLc92XvE1BovNbxnnKWKX6Ocglij%2FkIsXiyy%2BoCsyoIeeH9dr%2BgPvG97HeD%2BD%2Bqdv59IPqQxV%2FnO8ieMD%2F7WKfUgzkJ4%2BWSm7LRDYZgFexOxUSnC38ueCN9f1WKAud%2BHYrZCClm5GRTVx3VVFrBGW7sUAsarfETg%2FSzj8Eb0o77Dwm%2BXDD%2BA%2Fy3RchDf%2BNjPlKu54vRWxsvDTZX70MUh6oqLUP3rBmfD8M%2BEwTUItWIxd1vMkBbpQb9cqmvMJ59r5efNIAu%2F0KajQL34RWHqo3wd5NwxyJZ1ylRHoyo7k5a%2FBTGLroKVPKp7exgzeQirvlck1nv8NDp39wpQN0RlYFjseZ9zw7wdxEDqh77Ozy9YJyWl2%2F8F3BpDQ0jjXZl5mDW%2Bn01b5FE01K0cvxwje7XjmXApvejk0Kv29Dw3V45w6w8cvPgHqv9B%2B3N9DqHvAah9Sz%2Bp0V36ZOZO95u1kVNBpmnVrZRtum0GRe4ZZr057cGeQTo3OYhZM8Sy6Zect0ta4sc9PHu8OH02fwWQkHLyzqEsNxPzX8tz2w%2Bb1yw6hAQJQ7yDMgiBC9JVFDC8bjLGyN0z2ec%2FZvT1QvIiTOFtEf6AIXIg%3D%3D";
      iCashJSContext.call('OpenWebActivity', tradeToken,"3tgds");
    } catch (e: any) {
      alert(e);
    }
  }
}
